import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Link from "gatsby-link"
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
} from "mdbreact"

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const CompanyCarousel = ({ data }) => (
  <StaticQuery
    query={graphql`
      query SuccessLogos {
        allFile(filter: { extension: { eq: "jpg" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 200, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <MDBContainer>
        {console.log(data.allFile.edges.length)}
        <MDBCarousel
          activeItem={1}
          length={3}
          slide={true}
          showControls={false}
          showIndicators={true}
          multiItem
        >
          <MDBCarouselInner>
            {data.allFile.edges.map((item, index) => (
              <>
                {index % 3 === 0 && console.log(index === 0 ? 1 : index % 3 === 0 ? index / 3 : 0)}
                <ConditionalWrapper
                  // condition={index === 0 || (index !== 1 && index % 3 === 0)}
                  condition={index % 3 === 0 }
                  wrapper={children => (
                    <MDBCarouselItem itemId={ index === 0 ? 1 : index % 3 === 0 ? ((index / 3) + 1) : 0 }>{children}</MDBCarouselItem>
                  )}
                >
                  {(index % 3 === 0) && (
                    <MDBRow>
                      {/* {console.log(index)} */}
                      <MDBCol md="4" id={index}>
                        <MDBCard className="mb-2">
                          <Img
                            fluid={data.allFile.edges[index].node.childImageSharp.fluid}
                            className="text-center"
                          />
                        </MDBCard>
                      </MDBCol>

                      {index + 1 < data.allFile.edges.length && (
                        <MDBCol
                          md="4"
                          id={index + 1}
                          className="clearfix d-none d-md-block"
                        >
                          <MDBCard className="mb-2">
                            <Img
                              fluid={
                                data.allFile.edges[index + 1].node.childImageSharp.fluid
                              }
                              className="text-center"
                            />
                          </MDBCard>
                        </MDBCol>
                      )}

                      {index + 2 < data.allFile.edges.length && (
                        <MDBCol
                          md="4"
                          id={index + 2}
                          className="clearfix d-none d-md-block"
                        >
                          <MDBCard className="mb-2">
                            <Img
                              fluid={
                                data.allFile.edges[index + 2].node.childImageSharp.fluid
                              }
                              className="text-center"
                            />
                          </MDBCard>
                        </MDBCol>
                      )}
                    </MDBRow>
                  )}
                </ConditionalWrapper>
              </>
            ))}
          </MDBCarouselInner>
        </MDBCarousel>
      </MDBContainer>
    )}
  />
)

export default CompanyCarousel
