import React from "react"
import Link from "gatsby-link"
import "../styles/global.css"
import { graphql } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/SEO"
import CompanyCarousel from "../components/companyCarousel"

export default ({data}) => (
                    <Layout>
                        <SEO title="JTF starter site page-3" description="A little randomness - powered by Gatsby!" />
                        <div className="content">
                            <h1>Array Handling!</h1>
                            
                            <CompanyCarousel />

                            <Link to="/">Home</Link>
                        </div>
                    </Layout>
)

export const query = graphql `
    query MyFilesNewQuery {
      allFile(filter: {extension: {eq: "jpg"}}) {
            edges {
              node {
                extension
                relativePath
                prettySize 
                birthTime(fromNow: true)        
              }
            }
          }
        }
`